import React, { Component } from "react";
import AddDropdown from "./AddDropdown";
import { Link, NavLink } from "react-router-dom";
import navigationConfig from "../../contents/icons";
import Title from "../../components/title";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Col,
  Row,
} from "reactstrap";
import { history } from "../../app/AppRouter";
import {
  addGymModal,
  addTrainerModal,
  addAdminModal,
  addSubAdminModal,
  addProductModal,
  setSidebarStatus,
} from "../../redux/actions/sidebar/sidebarAction";
import { ComonIcons } from "../../contents/commonIcons";
import AddForm from "../../views/pages/gyminfo/gymForm";
import AddTrainer from "../../views/pages/trainers/trainerForm";
import SubAdminForm from "../superGymAdmin/subAdmin/addSubAdminForm";
import {
  createGym,
  createTrainer,
  createAdmin,
  logout,
  getAdmin,
  getUserstate,
  getBranches,
} from "../../redux/actions/api/apiAction";
import AddAdmin from "../../components/admin/adminForm";
import { connect } from "react-redux";
import { store } from "../../redux/storeConfig/store";
import { setUserState } from "../../redux/actions/login/loginAction";
import SwitchBranches from "./switchDropDownList";
import MultiStepForRetail from "../superGymAdmin/retail/retailForm";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: true,
    };
  }
  state = {
    sidebarOpen: false,
    addModal: false,
  };

  sidebarToggle() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
    });
  }

  async componentDidMount() {
    await this.props.getBranches();
  }
  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  addTrainer = async ({ name, email, phone, startDate, endDate, country }) => {
    await this.props.createTrainer({
      name,
      email,
      phone,
      role: "TRAINER",
      startDate: new Date(startDate).getTime(),
      endDate: new Date(endDate).getTime(),
      country,
    });
    // this.props.addTrainerModal(!this.props.trainerModal);
  };
  addAdmin = async ({ name, email, phone, permissions, image }) => {
    await this.props.createAdmin({
      name,
      email,
      phone,
      permissions: permissions,
      role: "ADMIN",
      image,
    });
    this.props.addAdminModal(!this.props.adminModal);
  };
  addGym = async ({
    name,
    email,
    phone,
    subscriptionPlan,
    startDate,
    endDate,
    activeStatus,
    canLive,
    image,
    userType,
    country,
  }) => {
    await this.props.createGym({
      name,
      email,
      phone,
      subscriptionPlan,
      startDate: new Date(startDate).getTime(),
      endDate: new Date(endDate).getTime(),
      activeStatus,
      canLive,
      role: "GYM",
      image,
      admin: JSON.parse(localStorage.getItem("user")).admin,
      userType,
      country,
    });
    // this.props.addGymModal(!this.props.gymModal);
  };

  render() {
    let userData = JSON.parse(localStorage.getItem("user"));
    let navItems = this.props.userState.permissions
      ? this.props.userState.permissions.map((one) => {
          return one.code;
        })
      : [];
    return (
      <div className={this.props.sidebarStatus ? "sidebar active" : "sidebar"}>
        <nav id="sidebar" className="sidebar-wrapper">
          <div className="sidebar-main-block ">
            <div className="user-main-block position-relative">
              <div className="user-img-block">
                <img
                  src={
                    this.props.userState.image
                      ? // JSON.parse(localStorage.getItem("user")).image
                        this.props.userState.image
                      : require("../../assets/images/no-image_user.jpg")
                  }
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="username">
                <h4 className="f-bold mb-0">
                  {/* {JSON.parse(localStorage.getItem("user")).name} */}
                  {this.props.userState && this.props.userState.name}
                </h4>
              </div>
              <div
                className="close-icon-sidebar"
                onClick={() => {
                  this.props.setSidebarStatus(!this.props.sidebarStatus);
                }}
              >
                {ComonIcons.close}
              </div>
            </div>
            <div className="seperator"></div>
            <div className="sidebar-dropdown">
              <AddDropdown
                addtoggle={() => {
                  this.props.addGymModal(!this.props.gymModal);
                }}
                addTrainerModal={() => {
                  this.props.addTrainerModal(!this.props.trainerModal);
                }}
                addAdminModal={() => {
                  this.props.addAdminModal(!this.props.adminModal);
                }}
                addProductModal={() => {
                  this.props.addProductModal(!this.props.adminModal);
                }}
                permissions={navItems}
              />
            </div>
            <div className="pages-main-link-block">
              <div className="list-ul">
                <ul className="list-main-block">
                  {navigationConfig.map((value, index) => {
                    console.log({
                      sun: this.props.subAdminType,
                      perms: this.props.subAdminPermissions,
                      code: value?.code,
                    });
                    if (
                      value.code &&
                      (Array.isArray(value.code)
                        ? (this.props.loginUser.role === "STAFF" &&
                            !value.code.some(
                              (code) => this.props.permissions[code]
                            )) ||
                          (this.props.subAdminType === "SUB_ADMIN" &&
                            !value.code.some(
                              (code) => this.props.subAdminPermissions[code]
                            ))
                        : (this.props.loginUser.role === "STAFF" &&
                            !this.props.permissions[value.code]) ||
                          (this.props.subAdminType === "SUB_ADMIN" &&
                            !this.props.subAdminPermissions[value.code]))
                    ) {
                    } else {
                      return (
                        <li
                          className={`list-element ${
                            history.location.pathname.includes(value.navLink)
                              ? "active"
                              : " "
                          }`}
                        >
                          <div className="curve">
                            <div className="list-element-upper-curve"></div>
                          </div>
                          <NavLink
                            exact
                            to={value.navLink}
                            className="link-blk"
                          >
                            <div className="link-data">
                              <div className="link-img">{value.icon}</div>
                              <div className="link-text">{value.title}</div>
                            </div>
                          </NavLink>
                          <div className="curve-2">
                            <div className="list-element-upper-curve-2"></div>
                          </div>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div className="side-bar-footer">
          <div className="out-block">
            <Row>
              <Col md={7}>
                <Link
                  to="/"
                  className="link-main-block"
                  onClick={() => store.dispatch(logout(userData.id))}
                >
                  <div className="link-data">
                    <div className="d-flex align-items-center">
                      <div className="link-img">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            id="Admin-account"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                            opacity="1"
                          >
                            <g
                              id="Admin-Dashboard"
                              transform="translate(-32.000000, -1682.000000)"
                            >
                              <g
                                id="Side-Menu"
                                transform="translate(0.000000, 70.000000)"
                              >
                                <g
                                  id="Sign-Out"
                                  transform="translate(0.000000, 1601.000000)"
                                >
                                  <g
                                    id="power_settings_new-24px"
                                    transform="translate(32.000000, 11.000000)"
                                  >
                                    <polygon
                                      id="Path"
                                      points="0 0 24 0 24 24 0 24"
                                    ></polygon>
                                    <path
                                      d="M12,3 C11.45,3 11,3.45 11,4 L11,12 C11,12.55 11.45,13 12,13 C12.55,13 13,12.55 13,12 L13,4 C13,3.45 12.55,3 12,3 Z M17.14,5.86 C16.75,6.25 16.76,6.86 17.13,7.25 C18.26,8.45 18.96,10.05 19,11.82 C19.09,15.65 15.92,18.95 12.09,18.99 C8.18,19.05 5,15.9 5,12 C5,10.16 5.71,8.49 6.87,7.24 C7.24,6.85 7.24,6.24 6.86,5.86 C6.46,5.46 5.81,5.47 5.43,5.88 C3.98,7.42 3.07,9.47 3,11.74 C2.86,16.62 6.83,20.84 11.71,20.99 C16.81,21.15 21,17.06 21,11.99 C21,9.62 20.08,7.48 18.58,5.88 C18.2,5.47 17.54,5.46 17.14,5.86 L17.14,5.86 Z"
                                      id="Shape"
                                      fill="#000000"
                                      fill-rule="nonzero"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>

                      <div className="content">Sign Out</div>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col md={2} className="ml-0 mt-1">
                <SwitchBranches />
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          isOpen={this.props.gymModal}
          toggle={() => {
            this.props.addGymModal(!this.props.gymModal);
          }}
          centered={true}
          size="xl"
          className="large-pop adding-popup add-gym-popup "
        >
          <ModalBody>
            <div className="modal-body-content">
              <div className="bg-white card-panel">
                <div className="basic-info">
                  <div className="info-title with-border">
                    <Title
                      title="Add Gym"
                      titlestyle="red"
                      subtitlestyle="ctf-tx-secondary"
                      subtitle="Add info about the Gym"
                    />
                  </div>
                  <AddForm
                    handleSubmit={this.addGym}
                    subscriptionPlan={""}
                  ></AddForm>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.props.subAdminModal}
          toggle={() => {
            this.props.addSubAdminModal(!this.props.subAdminModal);
          }}
          centered={true}
          size="xl"
          className="large-pop adding-popup add-gym-popup "
        >
          <ModalBody>
            <div className="modal-body-content">
              {/* <div className="bg-white card-panel"> */}
              <div className="basic-info">
                <SubAdminForm
                  handleSubmit={this.addGym}
                  subscriptionPlan={""}
                ></SubAdminForm>
                {/* </div> */}
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.props.trainerModal}
          toggle={() => {
            this.props.addTrainerModal(!this.props.trainerModal);
          }}
          centered={true}
          size="xl"
          className="large-pop adding-popup add-gym-popup "
        >
          <ModalBody>
            <div className="modal-body-content">
              <div className="bg-white card-panel">
                <div className="basic-info">
                  <div className="info-title with-border">
                    <Title
                      title="Add Trainer"
                      titlestyle="red"
                      subtitlestyle="ctf-tx-secondary"
                      subtitle="Add Info About Gym"
                    />
                  </div>
                  <AddTrainer handleSubmit={this.addTrainer} />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.props.adminModal}
          toggle={() => {
            this.props.addAdminModal(!this.props.adminModal);
          }}
          centered={true}
          size="xl"
          className="large-pop adding-popup add-gym-popup "
        >
          <ModalBody>
            <div className="modal-body-content">
              <div className="bg-white card-panel">
                <div className="basic-info">
                  <div className="info-title with-border">
                    <Title
                      title="Add Admin"
                      titlestyle="red"
                      subtitlestyle="ctf-tx-secondary"
                      subtitle="Add info about the Admin"
                    />
                  </div>
                  <AddAdmin handleSubmit={this.addAdmin} permissions={[]} />
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.props.productModal}
          toggle={() => {
            this.props.addProductModal(!this.props.productModal);
          }}
          centered={true}
          size="xl"
          className="large-pop adding-popup add-member-popup"
        >
          <MultiStepForRetail />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    sidebarStatus,
    gymModal,
    trainerModal,
    adminModal,
    subAdminModal,
    productModal,
  } = state.sidebar;
  const { admin } = state.admin;
  const { loginUser, loginUserData } = state.login;
  const { subAdminType, subAdminPermissions } = state.permissions;
  // console.log(state.login);
  return {
    sidebarStatus,
    gymModal,
    trainerModal,
    adminModal,
    loginUser,
    admin,
    userState: loginUserData,
    subAdminModal,
    productModal,
    subAdminType,
    subAdminPermissions,
  };
};
export default connect(mapStateToProps, {
  addGymModal,
  addTrainerModal,
  addAdminModal,
  setSidebarStatus,
  createGym,
  createTrainer,
  createAdmin,
  getBranches,
  addSubAdminModal,
  addProductModal,
})(Sidebar);
