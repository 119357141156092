import { SET_NOTIFICATIONS_DATA, SET_UNREAD_NOTIFICATION_COUNT,  SET_PAGE_LIMIT } from '../types/types';

export const setNotifications = (value, totalCount) => {
  return {
    type: SET_NOTIFICATIONS_DATA,
    payload: { data: value, totalCount },
  };
};

export const setUnreadNotificationCount = (value) => {
  return {
    type: SET_UNREAD_NOTIFICATION_COUNT,
    payload: { data: value },
  };
};

export const setPageLimit = (page,limit) => {
  return {
    type: SET_PAGE_LIMIT,
    payload: { page , limit },
  };
};
