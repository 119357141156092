import { setLoginUser, setLoginFlag, setUserState } from "../login/loginAction";
import {
  setGymData,
  setGymsData,
  setGymCount,
  setBranchesData,
  setSuperGymAdminInfo,
  setSuperGymAdminDashboardTotals,
  setGoverneData,
  setGymCategories,
} from "../gym/gymAction";
import {
  setTrainersData,
  setTrainerData,
  setTrainerCount,
  setStaffData,
  setStaffCount,
  setSubAdminsData,
  setSubAdminCount,
} from "../trainer/trainerAction";
import { setPlanData } from "../subscriptionPlan/subscriptionPlanAction";
import {
  setPermissionData,
  setSubAdminPermissions,
  setSubAdminType,
} from "../permission/permissionAction";
import { setMessageData } from "../messageAction";
import {
  setAdminData,
  setAdminsData,
  setAdminCount,
} from "../admin/adminAction";
import { setAccountsData, setAccountCount } from "../account/accountAction";
import {
  setSoftwareDashboardAccountCount,
  setSoftwareDashboardAccountsData,
  setSoftwareDashboardAccountData,
} from "../dashboard/softwareDashboardAction";
import {
  setPaymentDashboardAccountCount,
  setPaymentDashboardAccountsData,
  setPaymentDashboardAccountData,
} from "../dashboard/paymentDashboardAction";
import {
  setRevenueDashboardAccountCount,
  setRevenueDashboardAccountsData,
  setRevenueDashboardAccountData,
} from "../dashboard/revenueDashboardAction";
import {
  setDashboardCardData,
  setBookingCardData,
  setAllAccounts,
  setLiveClassCardData,
  setBookings,
  setSales,
  setExpansionCount,
  setExpansionSummary,
} from "../dashboard/dashboardAction";
import {
  setMembersData,
  setMemberCount,
  setMembers,
  setMembersCount,
} from "../member/memberAction";
import { setUserPermissionData } from "../permission/permissionAction";
import { setPrivacyPolicy, setTerms } from "../agreement/agreementAction";
import { api } from "../../../api/api";
import { history } from "../../../app/AppRouter";
import {
  addGymModal,
  addProductModal,
  addSubAdminModal,
  addTrainerModal,
} from "../sidebar/sidebarAction";
import { toastr } from "react-redux-toastr";
import { setReviewCount, setReviewList } from "../review/reviewAction";
import {
  setPaymentGateways,
  setPaymentGatewaysFields,
  setPaymentGatewayFieldSValue,
  setSaveGatewayValueFields,
  setPaymentGatewaysUsed,
} from "../paymentGateways/paymentGatewaysAction";
import {
  setProduct,
  setProductEdit,
  setProductExpiryDates,
  setProductExpiryDatesCount,
  setProductHistory,
  setProductHistoryCount,
  setProducts,
  setProductsCount,
  setSuppliers,
} from "../product/productAction";
import { SET_PAYMENT_GATEWAY_FIELDS_ERROR } from "../../actions/types/types";
import {
  setNotifications,
  setPageLimit,
  setUnreadNotificationCount,
} from "../notification/notificationAction";
import { error } from "autoprefixer/lib/utils";
import {
  setAllReportsData,
  setReportsCount,
  setReportsData,
  setSalesTotalData,
} from "../reports/reportsAction";

export const loginApi = (value) => {
  return async (dispatch, store) => {
    let res = await api(
      "super-gym-admin/login",
      value,
      "postWithoutToken"
    ).catch((err) => {});

    if (res.status === 403) {
      toastr.error("Not Allowed");
      return;
    }

    if (res.status === 400) {
      toastr.error(res.data.error);
    } else {
      if (res.data.data && res.data.data.role === "GYM") {
        toastr.error("Not allowed to login");
        return;
      }
      if (res.data.data && res.data.data.role === "TRAINER") {
        toastr.error("Not allowed to login");
        return;
      }
      dispatch(setLoginUser(res.data.data));

      localStorage.setItem("user", JSON.stringify(res.data.data));
      dispatch(setLoginFlag(true));
      dispatch(getUserstate(res.data.data.id));
      if (res?.data?.data?.role == "SUB_ADMIN") {
        dispatch(getsubAdminPermissions(res.data.data.id));
      }
      dispatch(
        setMessageData({
          type: "success",
          message: "Successfully logged in",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);

      history.push("/dashboard");
    }
  };
};
// export const loginApi = (value) => {
//   return async (dispatch, store) => {
//     // dispatch(setloader(true));
//     await api('login', value, 'postWithoutToken')
//       .then((res) => {
//         if (res.status === 400) {
//           toastr.error(res.data.error);
//         } else {
//           if(res.data.data && res.data.data.role === "ADMIN")
//           {
//             toastr.error("Not allowed to login");
//             return;
//           }
//           dispatch(setLoginUser(res.data.data));
//           localStorage.setItem(
//             'connectToFitGymPanelUser',
//             JSON.stringify(res.data.data)
//           );
//           dispatch(setLoginFlag(true));
//           toastr.success("Success : Logged In");
//           if (res.data.data.role === 'GYM') {
//             history.push('/gym-panel/dashboard');
//           }
//           if (res.data.data.role === 'TRAINER') {
//             history.push('/trainer-panel/dashboard');
//           }
//         }
//       })
//       .catch((err) => {});
//   };
// };
export const updateUserApi = (id, value) => {
  return async (dispatch, store) => {
    let res = await api(`user/${id}`, value, "patch").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      if (res.data.data.id === JSON.parse(localStorage.getItem("user")).id) {
        res.data.data.token = JSON.parse(localStorage.getItem("user")).token;
        dispatch(setLoginUser(res.data.data));

        localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch(setLoginFlag(true));
        dispatch(
          setMessageData({
            type: "success",
            message: "User updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(setMessageData({}));
        }, 5000);
      } else {
        dispatch(
          setMessageData({
            type: "success",
            message: "Admin updated successfully",
          })
        );
        setTimeout(() => {
          dispatch(setMessageData({}));
        }, 4000);
      }

      // toastr.success('Updated successfully');
    }
  };
};
export const getAdmin = (id) => {
  return async (dispatch, store) => {
    let res = await api(`user/${id}`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setAdminData(res.data.data));
    }
  };
};
export const getUserstate = (id) => {
  // console.log("ffffff")
  return async (dispatch, store) => {
    let res = await api(`user/${id}`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setUserState(res.data.data));
    }
  };
};

export const createGym = (value) => {
  return async (dispatch, store) => {
    let res = await api(`super-gym-admin/branch`, value, "post").catch(
      (err) => {}
    );
    // let res=await Axios.post('super-gym-admin/branch',value)

    if (res.status === 400) {
      toastr.error(res.data.error);
      return;
    } else {
      // getGyms(1, 10);
      dispatch(
        setMessageData({
          type: "success",
          message: "Gym created successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      dispatch(addGymModal(false));
      // history.push("/gyminfolist");
    }
  };
};

export const editGym = (id, value) => {
  return async (dispatch, store) => {
    let res = await api(`gym/${id}`, value, "patch").catch((err) => {});

    if (res.status === 400) {
      toastr.error(res.data.error);
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Gym updated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      history.push("/gyminfolist");
    }
  };
};

export const getGym = (id) => {
  return async (dispatch, store) => {
    let res = await api(`gym/${id}`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setGymData(res.data.data));
    }
  };
};

export const deleteGym = (id) => {
  return async (dispatch, store) => {
    let res = await api(`gym/${id}`, {}, "delete").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Gym deactivated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);

      history.push("/gyminfolist");
    }
  };
};

export const hardDeleteGym = (id) => {
  return async (dispatch, store) => {
    let res = await api(`gym/${id}/hardDelete`, {}, "delete").catch(
      (err) => {}
    );

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Gym deleted successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);

      history.push("/gyminfolist");
    }
  };
};

export const activateGym = (id) => {
  return async (dispatch, store) => {
    let res = await api(`gym/${id}/activate`, {}, "patch").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Gym activated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);

      history.push("/gyminfolist");
    }
  };
};

export const getGyms = (
  page,
  limit,
  search,
  sort = "g.createdAt desc",
  filter = "",
  isActive = ""
) => {
  return async (dispatch, store) => {
    let url = search
      ? `gym?page=${page}&limit=${limit}&name=${search}&sort=${sort}&isActive=${filter}`
      : `gym?page=${page}&limit=${limit}&sort=${sort}&isActive=${filter}`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setGymsData(res.data.data));
      dispatch(setGymCount(res.data.totalCount));
    }
  };
};

export const getGymsForExport = (
  page,
  limit,
  search,
  sort = "g.createdAt desc",
  filter = "",
  isActive = ""
) => {
  return async (dispatch, store) => {
    let url = search
      ? `gym?page=${page}&limit=${limit}&name=${search}&sort=${sort}&isActive=${filter}`
      : `gym?page=${page}&limit=${limit}&sort=${sort}&isActive=${filter}`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const getPlans = () => {
  return async (dispatch, store) => {
    let res = await api(`plan`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setPlanData(res.data.data));
    }
  };
};
export const getPermissions = () => {
  return async (dispatch, store) => {
    let res = await api(`permission`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setPermissionData(res.data.data));
    }
  };
};
export const getTrainer = (id) => {
  return async (dispatch, store) => {
    let res = await api(`trainer/${id}`, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setTrainerData(res.data.data));
    }
  };
};
export const getBranches = (id) => {
  return async (dispatch, store) => {
    let res = await api(`super-gym-admin/branch`, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setBranchesData(res.data.data));
    }
  };
};

export const getGovernances = (id) => {
  return async (dispatch, store) => {
    let res = await api(`super_gym_admin/governaces`, {}, "get").catch(
      (err) => {}
    );
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      // return res.data.data
      dispatch(setGoverneData(res.data.data));
    }
  };
};

export const redirectToGym = (body) => {
  return async (dispatch, store) => {
    let res = await api(
      `super-gym-admin/dashboard/redirectToGym`,
      body,
      "post"
    ).catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data;
    }
  };
};

export const createSubAdmin = (data) => {
  return async (dispatch, store) => {
    await api("subAdmin", data, "post").then((res) => {
      if (res.status === 400) {
        toastr.error(res.data.error);
      } else {
        dispatch(addSubAdminModal(false));
        toastr.success("Success:Admin Added");
        history.push("/adminInfolist");
      }
    });
  };
};
export const createTrainer = (value) => {
  return async (dispatch, store) => {
    let res = await api(`trainer`, value, "post").catch((err) => {});

    if (res.status === 400) {
      toastr.error(res.data.error);
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Trainer created successfully",
        })
      );

      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      dispatch(addTrainerModal(false));
      history.push("/trainerinfolist");
      // dispatch(setTrainerData({}))
      // getTrainers(
      //     1,
      //     10,
      //     JSON.parse(localStorage.getItem('connectToFitGymPanelUser')).gym,
      //     ""
      //   )
    }
  };
};

export const editTrainer = (id, value) => {
  return async (dispatch, store) => {
    let res = await api(`trainer/${id}`, value, "patch").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Trainer updated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);

      history.push("/trainerinfolist");
    }
  };
};

export const getStaff = (page = 1, limit = 10, search = "", branch = "") => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `super-gym-admin/staff?page=${page}&limit=${limit}&name=${search}&branch=${
          branch || ""
        }`
      : `super-gym-admin/staff?page=${page}&limit=${limit}&branch=${
          branch || ""
        }`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setStaffData(res.data.data));
      dispatch(setStaffCount(res.data.totalCount));
    }
  };
};
export const getSuperGymAdminInfo = () => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = `super-gym-admin/personal-info`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setSuperGymAdminInfo(res.data.data));
    }
  };
};
export const getSuperGymAdminDashboardTotals = (start, end, isForExport) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = `super-gym-admin/dashboard/totals?from=${start}&to=${end}`;
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      if (!isForExport)
        dispatch(setSuperGymAdminDashboardTotals(res.data.data));
      return res.data.data;
    }
  };
};
export const getTrainers = (
  page,
  limit,
  search,
  sort = "createdAt desc",
  filter = ""
) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `super-gym-admin/trainer?page=${page}&limit=${limit}&name=${search}&sort=${sort}&filter=${filter}`
      : `super-gym-admin/trainer?page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setTrainersData(res.data.data));
      dispatch(setTrainerCount(res.data.totalCount));
    }
  };
};
export const getSubAdmins = (
  page,
  limit,
  search,
  sort = "createdAt desc",
  filter = ""
) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `super-gym-admin/admins?page=${page}&limit=${limit}&name=${search}&sort=${sort}&filter=${filter}`
      : `super-gym-admin/admins?page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setSubAdminsData(res.data.data));
      dispatch(setSubAdminCount(res.data.totalCount));
    }
  };
};
export const getAllMembers = ({
  page,
  limit,
  search,
  sort = "createdAt desc",
  branch = "",
}) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `super-gym-admin/members?page=${page}&limit=${limit}&search=${search}&sort=${sort}&branch=${branch}`
      : `super-gym-admin/members?page=${page}&limit=${limit}&sort=${sort}&branch=${branch}`;
    console.log("before res");
    let res = await api(url, {}, "get").catch((err) => {});
    console.log("after res");

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setMembers(res.data.data));
      dispatch(setMembersCount(res.data.totalCount));
    }
  };
};
export const updateSuperGymAdmin = (data) => {
  return async (dispatch, store) => {
    let res = await api(`super-gym-admin`, data, "patch").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setSuperGymAdminInfo(res.data.data));
      dispatch(
        setMessageData({
          type: "success",
          message: "Info updated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      dispatch(addGymModal(false));
    }
  };
};

export const getTrainersForExport = (
  page,
  limit,
  search,
  sort = "t.createdAt desc",
  filter = ""
) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `trainer?page=${page}&limit=${limit}&name=${search}&sort=${sort}&isActive=${filter}`
      : `trainer?page=${page}&limit=${limit}&sort=${sort}&isActive=${filter}`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};
export const deleteTrainer = (id) => {
  return async (dispatch, store) => {
    let res = await api(`trainer/${id}`, {}, "delete").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Trainer deactivated successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      history.push("/trainerinfolist");
    }
  };
};

export const getMembers = (
  page,
  limit,
  search,
  sort = "createdAt desc",
  filter = ""
) => {
  //console.log('getMembers', search);
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `admin/member?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}`
      : `admin/member?page=${page}&limit=${limit}&sort=${sort}&isActive=${filter}`;
    //console.log(url);
    await api(url, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          dispatch(setMembersData(res.data.data));
          dispatch(setMemberCount(res.data.totalCount));
        }
      })
      .catch((err) => {});
  };
};

export const getMembersForExport = (
  page,
  limit,
  search,
  sort = "createdAt desc",
  filter = ""
) => {
  //console.log('getMembers', search);
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    let url = search
      ? `admin/member?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}`
      : `admin/member?page=${page}&limit=${limit}&sort=${sort}&isActive=${filter}`;
    //console.log(url);
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const createAdmin = (value) => {
  return async (dispatch, store) => {
    let res = await api(`user`, value, "post").catch((err) => {});

    if (res.status === 400) {
      toastr.error("Some error occured try again");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Admin created successfully",
        })
      );

      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      dispatch(addTrainerModal(false));
      history.push("/admininfolist");
    }
  };
};

export const getAdmins = (page, limit, search) => {
  return async (dispatch, store) => {
    let url = search
      ? `user?page=${page}&limit=${limit}&role=ADMIN&name=${search}`
      : `user?page=${page}&limit=${limit}&role=ADMIN`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setAdminsData(res.data.data));
      dispatch(setAdminCount(res.data.totalCount));
    }
  };
};

export const getAdminsForExport = (page, limit, search) => {
  return async (dispatch, store) => {
    let url = search
      ? `user?page=${page}&limit=${limit}&role=ADMIN&name=${search}`
      : `user?page=${page}&limit=${limit}&role=ADMIN`;
    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const deleteAdmin = (id) => {
  return async (dispatch, store) => {
    let res = await api(`user/${id}`, {}, "delete").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(
        setMessageData({
          type: "success",
          message: "Admin deleted successfully",
        })
      );
      setTimeout(() => {
        dispatch(setMessageData({}));
      }, 5000);
      history.push("/admininfolist");
    }
  };
};
export const getAccounts = (
  page,
  limit,
  search = "",
  sort = "A.startDate asc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `accounts?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setAccountsData(res.data.data));
      dispatch(setAccountCount(res.data.totalCount));
    }
  };
};

export const getAccountsForExport = (
  page,
  limit,
  search = "",
  sort = "A.startDate asc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `accounts?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const getReview = (page, limit) => {
  return async (dispatch, store) => {
    let res = await api(
      `admin/review?page=${page}&limit=${limit}`,
      {},
      "get"
    ).catch((err) => {});

    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setReviewList(res.data.data));
      dispatch(setReviewCount(res.data.totalCount));
    }
  };
};

export const allowReview = (id, changeStatus, page, limit) => {
  return async (dispatch, store) => {
    let res = await api(
      `admin/review/${id}`,
      { isApproved: changeStatus },
      "patch"
    ).catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(getReview(page, limit));
    }
  };
};

// export const logout = (id) => {
//   return async (dispatch, store) => {

//    let res = await api(`logout/${id}`, {}, 'get').catch((err) => {});

//         if (res.status === 400) {
//           toastr.error('some error occured');
//         } else {
//           localStorage.removeItem("user");
//           toastr.success('Success : User Logout');
//         }

//   };
// };
export const logout = (id) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api(`logout/${id}`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          localStorage.removeItem("user");
          toastr.success("Success : User Logout");
          dispatch(setLoginUser({}));
          dispatch(setLoginFlag(false));
          dispatch(setUserState({}));
          setTimeout(function () {
            dispatch({ type: "USER_LOGGED_OUT_SET_INITIAL" });
          }, 1500);
        }
      })
      .catch((err) => {});
  };
};

export const sendForgotEmail = (email) => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(
      `admin/forgotPassword`,
      { email: email },
      "patch"
    ).catch((err) => {});
    if (res && res.status !== 200) {
      toastr.error("Error : Invalid data");
      return false;
    } else {
      toastr.success("Success : OTP sent on e-mail");
      return true;
    }
  };
};

export const resetPassword = (data, flag) => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`admin/resetPassword`, data, "patch").catch(
      (err) => {}
    );
    if (res && res.status !== 200) {
      toastr.error("Error : Something went wrong. Try Again");
    } else {
      toastr.success("Success : Password Changed");
      if (flag) history.push("/");
    }
  };
};

export const softwareDashboardDataApi = (
  page,
  limit,
  search = "",
  sort = "A.startDate asc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/software?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      dispatch(setSoftwareDashboardAccountsData(res.data.data));
      dispatch(setSoftwareDashboardAccountCount(res.data.totalCount));
    }
  };
};

export const softwareDashboardDataApiForExport = (
  page,
  limit,
  search = "",
  sort = "A.startDate asc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/software?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const paymentDashboardDataApi = (
  page,
  limit,
  search = "",
  sort = "t.createdAt desc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/payment?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      dispatch(setPaymentDashboardAccountsData(res.data.data));
      dispatch(setPaymentDashboardAccountCount(res.data.totalCount));
    }
  };
};

export const paymentDashboardDataApiForExport = (
  page,
  limit,
  search = "",
  sort = "t.createdAt desc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/payment?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const revenueDashboardDataApi = (
  page,
  limit,
  search = "",
  sort = "class_count desc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/revenue?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      dispatch(setRevenueDashboardAccountsData(res.data.data));
      dispatch(setRevenueDashboardAccountCount(res.data.totalCount));
    }
  };
};

export const revenueDashboardDataApiForExport = (
  page,
  limit,
  search = "",
  sort = "class_count desc",
  filter = "",
  role = ""
) => {
  return async (dispatch, store) => {
    let url = `user/revenue?page=${page}&limit=${limit}&search=${search}&sort=${sort}&isActive=${filter}&role=${role}`;

    let res = await api(url, {}, "get").catch((err) => {});

    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const getSalesExpansionsForExport = (
  page,
  limit,
  search,
  filter,
  otherFilter,
  date
) => {
  return async (dispatch, store) => {
    let url = search
      ? `gym/dashboard/sales?page=${page}&limit=${limit}${search}`
      : `gym/dashboard/sales?page=${page}&limit=${limit}`;
    if (filter) {
      if (filter === "DATE") {
        if (date) {
          url = url.concat(`&from=${date.from}&to=${date.to}`);
        }
      } else {
        url = url.concat(`&${filter}`);
      }
    }
    if (otherFilter) {
      url = url.concat(`${otherFilter}`);
    }
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const getSalesExpansions = (
  page,
  limit,
  search,
  filter,
  otherFilter,
  date
) => {
  return async (dispatch, store) => {
    let url = search
      ? `gym/dashboard/sales?page=${page}&limit=${limit}${search}`
      : `gym/dashboard/sales?page=${page}&limit=${limit}`;
    if (filter) {
      if (filter === "DATE") {
        if (date) {
          url = url.concat(`&from=${date.from}&to=${date.to}`);
        }
      } else {
        url = url.concat(`&${filter}`);
      }
    }
    if (otherFilter) {
      url = url.concat(`${otherFilter}`);
    }
    await api(url, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          dispatch(setSales(res.data.data));
          dispatch(setExpansionSummary(res.data.counts[0]));
          dispatch(setExpansionCount(res.data.totalCount));
        }
      })
      .catch((err) => {});
  };
};

export const getBookingsExpansion = (page, limit, search, filter, date) => {
  return async (dispatch, store) => {
    console.log("filter", filter);
    let url = search
      ? `gym/dashboard/bookings?page=${page}&limit=${limit}&search=${search}`
      : `gym/dashboard/bookings?page=${page}&limit=${limit}`;
    if (filter) {
      if (filter === "DATE") {
        if (date) {
          url = url.concat(`&from=${date.from}&to=${date.to}`);
        }
      } else {
        url = url.concat(`&${filter}`);
      }
    }

    await api(url, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          dispatch(setBookings(res.data.data));
          dispatch(setExpansionCount(res.data.totalCount));
        }
      })
      .catch((err) => {});
  };
};

export const getBookingsExpansionForExport = (
  page,
  limit,
  search,
  filter,
  date
) => {
  return async (dispatch, store) => {
    console.log("filter", filter);
    let url = search
      ? `gym/dashboard/bookings?page=${page}&limit=${limit}&search=${search}`
      : `gym/dashboard/bookings?page=${page}&limit=${limit}`;
    if (filter) {
      if (filter === "DATE") {
        if (date) {
          url = url.concat(`&from=${date.from}&to=${date.to}`);
        }
      } else {
        url = url.concat(`&${filter}`);
      }
    }

    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      return res.data.data;
    }
  };
};

export const dashboardCardDataApi = () => {
  return async (dispatch, store) => {
    let res = await api(`user/count`, {}, "get").catch((err) => {});
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setDashboardCardData(res.data.data));
    }
  };
};

export const bookingCardDataApi = (from, to = new Date().getTime()) => {
  return async (dispatch, store) => {
    let res = await api(`user/stats?from=${from}&to=${to}`, {}, "get").catch(
      (err) => {}
    );
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setBookingCardData(res.data.data[0]));
    }
  };
};

export const bookingCardDataApiForExport = (
  from,
  to = new Date().getTime()
) => {
  return async (dispatch, store) => {
    let res = await api(`user/stats?from=${from}&to=${to}`, {}, "get").catch(
      (err) => {}
    );
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      return res.data.data[0];
    }
  };
};

export const liveClassCardDataApi = (
  from = new Date().getTime(),
  to = new Date().getTime(),
  trainer
) => {
  return async (dispatch, store) => {
    let url = trainer
      ? `user/stats/liveClass?from=${from}&to=${to}&${trainer}`
      : `user/stats/liveClass?from=${from}&to=${to}`;
    let res = await api(url, {}, "get").catch((err) => {});
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setLiveClassCardData(res.data.data));
    }
  };
};

export const allAccountsApi = () => {
  return async (dispatch, store) => {
    let res = await api(`accounts/all`, {}, "get").catch((err) => {});
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setAllAccounts(res.data.data));
    }
  };
};

export const permissionsApi = (id) => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`user/${id}`, {}, "get").catch((err) => {});
    if (res) {
      if (res.status !== 200) {
        // console.log('Error : Something went wrong. Try Again');
      } else {
        dispatch(setUserPermissionData(res.data.data.permissions || []));
      }
    }
  };
};

export const privacyPolicyApi = () => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`agreement?type=PP`, {}, "get").catch((err) => {});
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setPrivacyPolicy(res.data.data));
    }
  };
};

export const termsApi = () => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`agreement?type=TC`, {}, "get").catch((err) => {});
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
    } else {
      dispatch(setTerms(res.data.data));
    }
  };
};

export const updateTermsApi = (id, data) => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`agreement/${id}`, { enBody: data }, "patch").catch(
      (err) => {}
    );
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
      toastr.error(`Error : ${res.data.error}`);
    } else {
      toastr.success("Success : Terms and Conditions updated successfully");
      dispatch(setTerms(res.data.data));
    }
  };
};

export const updatePrivacyPolicyApi = (id, data) => {
  //console.log("Password", password)
  return async (dispatch, store) => {
    let res = await api(`agreement/${id}`, { enBody: data }, "patch").catch(
      (err) => {}
    );
    if (res && res.status !== 200) {
      // console.log('Error : Something went wrong. Try Again');
      toastr.error(`Error : ${res.data.error}`);
    } else {
      toastr.success("Success : Privacy Policy updated successfully");
      dispatch(setPrivacyPolicy(res.data.data));
    }
  };
};

export const createProduct = (payload) => {
  return async (dispatch, store) => {
    // dispatch(setLoading(true));
    let res = await api(`super-gym-admin/product`, payload, "post").catch(
      (err) => {}
    );
    if (res.status !== 201) {
      // dispatch(setLoading(false));
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      toastr.success("Success:Added Product");
      // dispatch(setLoading(false));
      dispatch(addProductModal(false));

      dispatch(getProducts({}));

      history.push("/retail");
    }
  };
};
export const getProductById = (id) => {
  return async (dispatch, store) => {
    let url = `super-gym-admin/product/${id}`;
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      console.log(res.data.data);
      dispatch(setProduct(res.data.data));
    }
  };
};
export const getProducts = ({ supplier, limit, page, search, dateFilter }) => {
  return async (dispatch, store) => {
    console.log({ limit, page });
    let url = `super-gym-admin/products?limit=${
      limit ? limit : store().product.productsLimit
    }&page=${page ? page : store().product.productsPage}`;
    if (supplier) {
      url += `&supplier=${supplier}`;
    }
    if (search) {
      url += `&name=${search}`;
    }
    if (dateFilter && dateFilter?.startDate) {
      url += `&startDate=${dateFilter?.startDate}`;
    }
    if (dateFilter && dateFilter?.endDate) {
      url += `&endDate=${dateFilter?.endDate}`;
    }
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setProducts(res.data.data));
      dispatch(setProductsCount(res.data.totalCount));
    }
  };
};
export const getProductExpiryDates = ({ id }) => {
  return async (dispatch, store) => {
    console.log(store().product.productExpiryDatesLimit);
    let url = `super-gym-admin/product/expiryDates/${id}?limit=${
      store().product.productExpiryDatesLimit
    }&page=${store().product.productExpiryDatesPage}`;

    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status !== 200) {
      toastr.error("some error occured");
    } else {
      dispatch(setProductExpiryDates(res.data.data));
      dispatch(setProductExpiryDatesCount(res.data.totalCount));
    }
  };
};

export const getUniqueSuppliers = () => {
  return async (dispatch, store) => {
    let url = "super-gym-admin/products/unique/suppliers";
    let res = await api(url, {}, "get").catch((err) => {});
    if (res.status === 400) {
      toastr.error("some error occured");
    } else {
      dispatch(setSuppliers(res.data.data));
    }
  };
};

export const getProductHistory = (payload) => {
  return async (dispatch, store) => {
    // dispatch(setLoading(true));
    let res = await api(
      `super-gym-admin/product/history/${payload}?limit=${
        store().product.productHistoryLimit
      }&page=${store().product.productHistoryPage}`,
      payload,
      "get"
    ).catch((err) => {});
    if (res.status !== 200) {
      // dispatch(setLoading(false));
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      // dispatch(setLoading(false));
      dispatch(setProductHistory(res.data.data));
      dispatch(setProductHistoryCount(res.data.totalCount));
    }
  };
};
export const updateProduct = (id, values) => {
  return async (dispatch, store) => {
    let url = `super-gym-admin/product/${id}`;
    let res = await api(url, values, "patch").catch((err) => {
      return toastr.error("err");
    });
    if (res?.status !== 200) {
      toastr.error(res?.data?.message);
    } else {
      toastr.success("Success:Updated Product");
      dispatch(getProducts(res.data.data));
      dispatch(setProductEdit(false));
    }
  };
};
export const purchaseProduct = (payload) => {
  console.log({ payload });
  return async (dispatch, store) => {
    let res = await api(
      `super-gym-admin/purchase/product`,
      payload,
      "post"
    ).catch((err) => {});
    if (res.status !== 201) {
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      toastr.success("Success:Product Purchased");
      dispatch(getProducts({}));
      return res.data.data;
    }
  };
};
export const transferProduct = (payload) => {
  console.log({ payload });
  return async (dispatch, store) => {
    // dispatch(setLoading(true));
    let res = await api(
      `super-gym-admin/product/transfer`,
      payload,
      "post"
    ).catch((err) => {});
    if (res.status !== 200) {
      // dispatch(setLoading(false));
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      toastr.success("Success:Product Transfered");
      // dispatch(setLoading(false));
      dispatch(getProducts({}));
      return res.data.data;
      // dispatch(setRetailModal(false))
      // dispatch(getGym(res.data.data.gym));

      // history.push("/gym-panel/services/retail")
    }
  };
};
export const returnToVendor = (payload) => {
  return async (dispatch, store) => {
    // dispatch(setLoading(true));
    let res = await api(
      `super-gym-admin/product/returnToVendor/${payload.product}`,
      payload,
      "patch"
    ).catch((err) => {});
    if (res.status !== 200) {
      // dispatch(setLoading(false));
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      toastr.success("Success:Product Returned Success");
      // dispatch(setLoading(false));
      dispatch(getProducts({}));
      return res.data.data;
      // dispatch(setRetailModal(false))
      // dispatch(getGym(res.data.data.gym));

      // history.push("/gym-panel/services/retail")
    }
  };
};
export const getsubAdminPermissions = (id) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api(`subAdmin/permissions/${id}`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          let permissions = res.data.data.permissions;
          let customisedPermisstion = {};
          for (let i = 0; i < permissions.length; i++) {
            customisedPermisstion[permissions[i].code] = true;
          }
          console.log({ permissions });
          // return customisedPermisstion;
          dispatch(setSubAdminPermissions(customisedPermisstion));
          dispatch(setSubAdminType(res.data.data.role));
        }
      })
      .catch((err) => {});
  };
};
export const notificationsApi = (page, limit) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api(`notifications?page=${page}&limit=${limit}`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          dispatch(setNotifications(res.data.data, res.data.totalCount));
          dispatch(setPageLimit(page, limit));
        }
      })
      .catch((err) => {});
  };
};

export const udateSubAdmin = (id, data) => {
  return async (dispatch, store) => {
    await api(`super_gym_admin/sub_admin/${id}`, data, "patch").then((res) => {
      if (res.status === 400) {
        toastr.error(res.data.error);
      } else {
        dispatch(addSubAdminModal(false, false, {}));
        toastr.success("Success:Sub Admin Updated");
        history.push("/adminInfolist");
      }
    });
  };
};
export const unreadNotificationCountApi = () => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api(`notifications/unread`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          dispatch(setUnreadNotificationCount(res.data.data));
        }
      })
      .catch((err) => {});
  };
};
export const markReadApi = (id) => {
  return async (dispatch, store) => {
    // dispatch(setloader(true));
    await api(`notifications/toggle/${id}`, {}, "get")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        }
      })
      .catch((err) => {});
  };
};
export const getPaymentGatewaysByRegion = (region) => async (dispatch) => {
  try {
    const url = `setting/super-gym/region/${region}`;
    const res = await api(url, {}, "get");

    if (res.status === 200) {
      dispatch(setPaymentGateways(res.data.data));
    }
  } catch (err) {}
};

export const getPaymentGatewaysFields = (gateway) => async (dispatch) => {
  try {
    const url = `setting/super-gym/gateway/${gateway}`;
    const res = await api(url, {}, "get");

    if (res.status === 200) {
      dispatch(setPaymentGatewaysFields(res.data.data));
    }
  } catch (err) {}
};

export const getPaymentGatewayFieldsValue =
  (paymentGateway) => async (dispatch) => {
    try {
      const url = `setting/super-gym/payment/${paymentGateway}`;
      const res = await api(url, {}, "get");

      if (res.status === 200) {
        dispatch(setPaymentGatewayFieldSValue(res.data.data));
      }
    } catch (err) {}
  };

export const setPaymentFieldsValue =
  (key, value, enabled) => async (dispatch) => {
    try {
      const url = "setting/super-gym/payment";
      const data = {
        key: key,
        value: value,
        enabled: enabled,
      };

      const response = await api(url, data, "post");

      if (response.status === 200) {
        dispatch(setSaveGatewayValueFields(response));
        toastr.success(response.data.message);
      }
    } catch (err) {
      dispatch({
        type: SET_PAYMENT_GATEWAY_FIELDS_ERROR,
        payload: err.toString(),
      });
      toastr.error(`Authentication with ${key} failed`);
    }
  };

export const getPaymentGatewaysUsed = () => async (dispatch) => {
  try {
    const url = `setting/super-gym/payment-gateway-used`;
    const res = await api(url, {}, "get");

    if (res.status === 200) {
      dispatch(setPaymentGatewaysUsed(res.data.data));
    } else {
      toastr.error(`Erorr`);
    }
  } catch (err) {}
};

export const getReports = ({ reportName, page, limit, query }) => {
  return async (dispatch, store) => {
    let url = `super-gym-admin/reports/${reportName}`;
    if (page && limit) {
      url += `?page=${page}&limit=${limit}&&timeZone=${
        Intl.DateTimeFormat().resolvedOptions().timeZone
      }`;
    }
    if (query) {
      Object.keys(query).forEach((item) => {
        url += `&${item}=${query[item]}`;
      });
    }
    try {
      let res = await api(url, {}, "get");
      let data = res.data.data;
      let count = res.data.totalCount;
      let totals = res.data.counts;
      if (page == 1 && limit == 9999) {
        dispatch(setAllReportsData(data));
      } else {
        // dispatch(getSalesExpansions(page, limit));
        dispatch(setReportsData(data));
        dispatch(setReportsCount(count));
        // dispatch(setSalesTotalData(totals));
      }
    } catch (e) {
      toastr.error("Something went wrong");
    }
  };
};

export const changePassword = (value) => {
  let id = JSON.parse(localStorage.getItem("user")).id;
  let data = {
    oldpassword: value.oldPassword,
    newpassword: value.newPassword,
  };
  return async (dispatch, store) => {
    await api(`changePassword/${id}`, data, "patch")
      .then((res) => {
        if (res.status === 400) {
          toastr.error("some error occured");
        } else {
          toastr.success("Password Updated Successfully");

          history.push("/settings");
        }
      })
      .catch((err) => {});
  };
};

export const getGymCategories = (payload) => {
  return async (dispatch, store) => {
    let res = await api(`admin/categories`, {}, "get").catch((err) => {
      //console.log(err);
    });
    if (
      String(res.status).startsWith("5") ||
      String(res?.response?.status).startsWith("5")
    ) {
      toastr.error("Something went wrong!");
      return;
    }
    if (res.status === 400) {
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      dispatch(setGymCategories(res.data.data));
    }
  };
};
export const deleteAdminCategories = (payload) => {
  return async (dispatch, store) => {
    let res = await api(
      `admin/delete/categories`,
      { categoriesToKeep: payload },
      "patch"
    ).catch((err) => {
      //console.log(err);
    });
    if (
      String(res.status).startsWith("5") ||
      String(res?.response?.status).startsWith("5")
    ) {
      toastr.error("Something went wrong!");
      return;
    }
    if (res.status === 400) {
      if (res.data.error) toastr.error(res.data.error);
      else toastr.error("Enter Valid data");
    } else {
      toastr.success("Categories Deleted");
      dispatch(getGymCategories());
    }
  };
};
